define("discourse/plugins/discourse-livestream/discourse/components/embeddable-chat-channel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/controller", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-button", "discourse-common/utils/decorators", "truth-helpers/helpers/and", "truth-helpers/helpers/not", "discourse/plugins/chat/discourse/components/chat-channel", "discourse/plugins/discourse-livestream/discourse/modifiers/toggle-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _controller, _helper, _object, _didInsert, _service, _dButton, _decorators, _and, _not, _chatChannel, _toggleClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmbedableChatChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "embeddableChat", [_service.service]))();
    #embeddableChat = (() => (dt7948.i(this, "embeddableChat"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "chatDraftsManager", [_service.service]))();
    #chatDraftsManager = (() => (dt7948.i(this, "chatDraftsManager"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "topicController", [(0, _controller.inject)("topic")]))();
    #topicController = (() => (dt7948.i(this, "topicController"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "topicModel", [_tracking.tracked], function () {
      return null;
    }))();
    #topicModel = (() => (dt7948.i(this, "topicModel"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "topicChannelId", [_tracking.tracked], function () {
      return null;
    }))();
    #topicChannelId = (() => (dt7948.i(this, "topicChannelId"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "loadingChannel", [_tracking.tracked], function () {
      return false;
    }))();
    #loadingChannel = (() => (dt7948.i(this, "loadingChannel"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "activeChannel", [_tracking.tracked]))();
    #activeChannel = (() => (dt7948.i(this, "activeChannel"), void 0))();
    constructor() {
      super(...arguments);
      this.messageBus.subscribe("discourse_livestream_update_livestream_chat_status", this.onMessage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.chatDraftsManager.reset();
      this.embeddableChat.activeChannel = null;
      this.messageBus.unsubscribe("discourse_livestream_update_livestream_chat_status", this.onMessage);
    }
    async onMessage(membership) {
      membership = JSON.parse(membership).user_channel_membership;
      this.activeChannel.currentUserMembership = membership;
    }
    static #_15 = (() => dt7948.n(this.prototype, "onMessage", [_decorators.bind]))();
    async findChannel(channelId) {
      try {
        this.loadingChannel = true;
        this.activeChannel = await this.chatChannelsManager.find(channelId);
      } finally {
        this.loadingChannel = false;
      }
    }
    static #_16 = (() => dt7948.n(this.prototype, "findChannel", [_object.action]))();
    closeChat() {
      this.embeddableChat.toggleChatVisibility();
    }
    static #_17 = (() => dt7948.n(this.prototype, "closeChat", [_object.action]))();
    static #_18 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          id="custom-chat-container"
          {{toggleClass this.embeddableChat.isMobileChatVisible "mobile"}}
          class={{unless this.embeddableChat.isMobileModal "no-modal-mobile"}}
          {{didInsert (fn this.findChannel @chatChannelId)}}
        >
          {{#unless this.embeddableChat.isMobileModal}}
            <div class="c-navbar-container livestream-chat-close">
    
              <DButton
                @icon="xmark"
                @action={{this.closeChat}}
                @title="chat.close"
                class="btn-transparent no-text c-navbar__close-drawer-button"
              />
            </div>
          {{/unless}}
          <div class="chat-drawer">
            {{#if (and this.activeChannel (not this.loadingChannel))}}
              <ChatChannel @channel={{this.activeChannel}} />
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "4wEAeB6J",
      "block": "[[[1,\"\\n    \"],[11,0],[24,1,\"custom-chat-container\"],[16,0,[52,[51,[30,0,[\"embeddableChat\",\"isMobileModal\"]]],\"no-modal-mobile\"]],[4,[32,0],[[30,0,[\"embeddableChat\",\"isMobileChatVisible\"]],\"mobile\"],null],[4,[32,1],[[28,[32,2],[[30,0,[\"findChannel\"]],[30,1]],null]],null],[12],[1,\"\\n\"],[41,[51,[30,0,[\"embeddableChat\",\"isMobileModal\"]]],[[[1,\"        \"],[10,0],[14,0,\"c-navbar-container livestream-chat-close\"],[12],[1,\"\\n\\n          \"],[8,[32,3],[[24,0,\"btn-transparent no-text c-navbar__close-drawer-button\"]],[[\"@icon\",\"@action\",\"@title\"],[\"xmark\",[30,0,[\"closeChat\"]],\"chat.close\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,0],[14,0,\"chat-drawer\"],[12],[1,\"\\n\"],[41,[28,[32,4],[[30,0,[\"activeChannel\"]],[28,[32,5],[[30,0,[\"loadingChannel\"]]],null]],null],[[[1,\"          \"],[8,[32,6],null,[[\"@channel\"],[[30,0,[\"activeChannel\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@chatChannelId\"],false,[\"unless\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-livestream/discourse/components/embeddable-chat-channel.js",
      "scope": () => [_toggleClass.default, _didInsert.default, _helper.fn, _dButton.default, _and.default, _not.default, _chatChannel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EmbedableChatChannel;
});